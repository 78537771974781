import Utils from '../lib/utils';
import Component from './component';

export default class AccreditedImage extends Component {
    constructor( props ) {
        super( props );
        this.state = Object.assign(this.state, !!props ? props : {} );
        this._key = 'AccreditedImage';
    }

    componentDidMount() {
        const self  = this;
        const el    = this.state.element;
        const $     = this.state.$;

        if( !el ) { throw "This component requires a valid DOM element."; }
        
        window.requestAnimationFrame(() => {
            self.processImage( $( el ) );
        });
    }

    processImage( asset ) {
        const self = this;
        const $ = this.state.$;

        let attributes = [];
        attributes.push(asset.data('credit'));
        attributes.push(asset.data('copyright'));
        let attribution = attributes.filter(e => e != null);
        
        if( !!attribution ) {
            let attr = attribution.length > 1 ? attribution.join(" | ") : attribution.shift() || null;
            asset.wrap( Utils.sprintf('<div class="accredited-image" data-attribution="%s" />', attr));
        }
    }
}