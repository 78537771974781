import Component from './component'

export default class RestaurantMenu extends Component {
    constructor( props ) {
        super( props )
        this.state = Object.assign(this.state, !!props ? props : {} )
        this.state.filters  = []
        this.state.category = 'all'
        this.state.current  = null
    }

    componentDidMount() {
        const self  = this
        const el    = this.state.element
        const $     = this.state.$

        if( !el ) { throw "This component requires a valid DOM element." }

        // Cache elements to iterate over later
        this.state.filters  = el.find('li[category]')
        this.state.groups   = el.find('griditem[group], griditem[footnotes], griditem[section]')

        el.on('click', 'li[category]', (e) => {
            let obj         = $(e.target)
            let category    = (obj.attr('category') !== self.state.category) ? obj.attr('category') : 'all'

            window.requestAnimationFrame(() => {
                // Reset everything first
                self.reset()

                // Toggle by category
                self.select(obj, category)
            })

            e.preventDefault()
            e.stopImmediatePropagation()
            return false

        })
    }

    reset(obj) {
        const self  = this
        const el    = this.state.element
        const $     = this.state.$

        // Reset filter selection
        if(this.state.filters?.length) {
            self.state.filters.removeClass('current')
            self.state.current = null
            self.state.category = null
        }
    }

    select(obj, category) {
        const self  = this
        const el    = this.state.element
        const $     = this.state.$

        if(this.state.groups?.length) {
            // Select category
            self.state.category = category
            el.attr('category', category)

            // Select current filter
            self.state.current  = obj
            if(self.state.category == 'all') {
                self.state.current.removeClass('current')
            }else{
                self.state.current.addClass('current')
            }

            // Filter nodelist + remove selection class
            const nodes = [...this.state.groups].filter( (v,k) => {
                let attr = !!v.getAttribute('category') ? v.getAttribute('category').split(',') : []
                v.classList.remove('current')
                return attr.includes(self.state.category)
            })

            // Select matching groups
            if(nodes?.length) {
                nodes.map((v) => {
                    const c = v.classList
                    c.add('current')
                })
            }
        }

    }
}
