
// CSS
import './scss/main.scss';

// JS Polyfills
import IntersectionObserver from 'intersection-observer';
import CustomEvent from 'custom-event-polyfill';

//JS Application
import $ from 'jquery';
import Umbra from './javascript/modules/umbra';
import Navigation from './javascript/components/navigation';
import MDForms from './javascript/components/mdforms';
import Forms from './javascript/components/forms';
import Fab from './javascript/components/fab';
import Appbar from './javascript/components/appbar';
import DeferredMedia from './javascript/components/deferredmedia';
import Carousel from './javascript/components/carousel';
import UIBlocker from './javascript/components/uiblocker';
import AccreditedImage from './javascript/components/accreditedimage';
import SmoothScroll from './javascript/components/smoothscroll';
import FormObserver from './javascript/components/formobserver';
import MassiveMenu from './javascript/components/massivemenu';
import Analytics from './javascript/components/analytics';
import LeadCTA from './javascript/components/leadcta';
import BlogLoadMore from './javascript/components/blogloadmore';
import Gallery from './javascript/components/gallery';
import AnimationObserver from './javascript/components/animationobserver';
import CookieConsent from './javascript/components/cookieconsent';
import RestaurantMenu from './javascript/components/restaurantmenu';
import Modal from './javascript/components/modal';
import Popup from './javascript/components/popup';

// Umbra singleton
const U = ('__umbrastate__' in window) ? new Umbra({state:{
    active:true,
    ...window['__umbrastate__']
}}) : new Umbra({state:{
    active:true
}});

// Ready callback (like jQuery's $())
U.ready(() => {
    U.subscribe('onComponentRegistered', () => {
        // console.log('onComponentRegistered',arguments);
    });

    U.subscribe('onStateChange', () => {
        // console.log('onStateChange',arguments);
    });

    // Recaptcha Initialization
    if( 'grecaptcha' in window && !!grecaptcha ) {
        const sitekey = $('script[data-sitekey]').data('sitekey');
        grecaptcha.ready(function () {
            grecaptcha
                .execute(sitekey, {action: "validate_captcha"})
                .then(function (e) {
                    document
                        .querySelectorAll(".recaptchaResponse")
                        .forEach(c => c.value = e)
                })
        });
    }

    // Initialize components
    let el = null;

    // Analytics
    el = $('body');
    if( !!el ) {
        const objAnalytics = new Analytics({app:U, element: el, $:$});
        U.registerComponent( objAnalytics.id, objAnalytics );
    }

    // Navigation
    el = $('body').get(0);
    if( !!el && 'IntersectionObserver' in window ) {
        const objNav = new Navigation({app:U, element: $('body').get(0), $:$});
        U.registerComponent( objNav.id, objNav );
    }else{
        if( 'warn' in window.console ) {
            console.warn('IntersectionObserver is not available in this browser.');
        }else{
            console.log('IntersectionObserver is not available in this browser.');
        }
    }

    // App bar
    el = $('.md-appbar').get(0);
    if( !!el ) {
        const objAppbar = new Appbar({app:U, element: el, $:$});
        U.registerComponent( objAppbar.id, objAppbar );
    }

    // Fab
    el = $('.md-fab').get(0);
    if( !!el ) {
        const objFab = new Fab({app:U, element: el, $:$});
        U.registerComponent( objFab.id, objFab );
    }

    // Material forms
    el = $('form.md-form');
    if( !!el && !!el.length ) {
        const objMDForm = new MDForms({app:U, element: el, $:$});
        U.registerComponent( objMDForm.id, objMDForm );
    }

    // AJAX enchanced forms
    el = $('form.md-form.md-form-enhanced');
    if( !!el && !!el.length ) {
        // Deal existing forms in the DOM
        el.map((k,v) => {
            const objEnhancedForm = new Forms({app:U, element: v, $:$});
            objEnhancedForm.key = `Forms_${k}`;
            U.registerComponent( objEnhancedForm.id, objEnhancedForm, objEnhancedForm.key );
        });
    }

    // UI Blocker
    let objUIBlocker = new UIBlocker({app:U, pure: true, $:$});
    U.registerComponent( objUIBlocker.id, objUIBlocker, objUIBlocker.key );

    // Carousels
    el = $('.carousel');
    if( !!el && el.length ) {
        let objCarousel = new Carousel({app:U, element: el, $:$});
        U.registerComponent( objCarousel.id, objCarousel );
    }

    // Containers
    el = $('main uielementalcontainer, body > header, main header, main banner, footer, fab, cookieconsent');
    if( !!el && el.length ) {
        let objAnimationObserver = new AnimationObserver({app:U, element: el, $:$});
        U.registerComponent( objAnimationObserver.id, objAnimationObserver );
    }

    // Lazy-load media
    el = $('body');
    if( el && !!el.length ) {
        const objDeferred = new DeferredMedia({app:U, element: el, $:$});
        U.registerComponent( objDeferred.id, objDeferred );
    }

    // Blog: Load More controls
    el = $('loadmore,.loadmore');
    if( el && !!el.length ) {
        const objBlogLoadMOre = new BlogLoadMore({app:U, element: el, $:$});
        U.registerComponent( objBlogLoadMOre.id, objBlogLoadMOre );
    }

    // Accredited images
    el = $('img[data-copyright], img[data-credit]');
    if( el && !!el.length ) {
        // Generate instances
        el.map((k,v) => {
            const objAccreditedImage = new AccreditedImage({app:U, element: v, $:$});
            objAccreditedImage.key = `AccreditedImage_${k}`;
            U.registerComponent( objAccreditedImage.id, objAccreditedImage, objAccreditedImage.key );
        });
    }

    // Lead CTA links
    el = $('body');
    if( el && !!el.length ) {
        const objLeadCta = new LeadCTA({app:U, element: el, $:$});
        U.registerComponent( objLeadCta.id, objLeadCta );

        // Popups
        const objPopup = new Popup({app:U, element: el, $:$});
        U.registerComponent( objPopup.id, objPopup );
    }

    // Modals
    el = $('body');
    if( el && !!el.length ) {
        const objModals = new Modal({app:U, element: el, $:$});
        U.registerComponent( objModals.id, objModals );
    }

    // SmoothScroll: Scroll to elements
    el = $('body');
    if( el && !!el.length ) {
        const objSmoothScroll = new SmoothScroll({app:U, element: el, $:$});
        objSmoothScroll.componentDidMount();
        U.registerComponent( objSmoothScroll.id, objSmoothScroll );

        const objFormObserver = new FormObserver({app:U, element: el, $:$});
        objFormObserver.componentDidMount();
        U.registerComponent( objFormObserver.id, objFormObserver );
    }

    // MassiveMenu
    el = $('massivemenu');
    if( el && !!el.length ) {
        const objMassiveMenu = new MassiveMenu({app:U, element: el, $:$});
        U.registerComponent( objMassiveMenu.id, objMassiveMenu );
    }

    el = $('.uielementalgallery:not([layout="cascade"])');
    if( el && !!el.length ) {
        const objGallery = new Gallery({app:U, element:el, $:$});
        U.registerComponent( objGallery.id, objGallery );
    }

    // Cookie Consent
    el = $('.cookieconsent');
    if( !!el ) {
        const objCookieConsent = new CookieConsent({app:U, element: el, $:$});
        U.registerComponent( objCookieConsent.id, objCookieConsent );
    }

    // Restaurant Menu
    el = $('.restaurantmenu');
    if( !!el ) {
        const objRestaurantMenu = new RestaurantMenu({app:U, element: el, $:$});
        U.registerComponent( objRestaurantMenu.id, objRestaurantMenu );
    }


    // Video (YouTube/Vimeo) BG Headers
    let elVideoBG = $('.videobg');
    let __videoTO = null;
    if( elVideoBG && !!elVideoBG.length ) {
        let header          = elVideoBG.closest('.uielementalpageheader');
        let playerWrapper   = elVideoBG.closest('.videobg--container');
        let delay           = elVideoBG.data('delay') || 5000;

        window.requestAnimationFrame(()=>{
            // Lazy loading
            if( elVideoBG.is('[loading="lazy"]')) {
                elVideoBG.on('load', () => {
                    __videoTO = setTimeout(() => {
                        playerWrapper.addClass('fadein');
                        if( header && !!header.length ) {
                            header.addClass('videoready');
                        }
                        clearTimeout(__videoTO);
                    }, delay);
                });
                elVideoBG.attr('src', elVideoBG.data('src'));
            }

        });
    }

    // Cleanup
    el = null;

});
