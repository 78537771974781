import Component from './component';

export default class SmoothScroll extends Component {
    constructor( props ) {
        super( props );
        this.state = Object.assign(this.state, !!props ? props : {} );
    }

    componentDidMount() {
        const self  = this;
        const el    = this.state.element;
        const $     = this.state.$;

        if( !el ) { throw "This component requires a valid DOM element."; }
        
        el.on('click', '.smoothscroll,.md-button--smoothscroll', (e) => {
            
            let obj         = $(e.target);
            let target      = obj.prop('hash') || null;
            let objTarget   = $( target );

            if( !!objTarget && !!objTarget.length  ) {
                let offset    = objTarget.offset();

                if( !!offset && 'top' in offset ) {
                    offset = offset.top;
                    window.requestAnimationFrame(() => {
                        scroll({
                            top: offset,
                            behavior: 'smooth'
                        });
                    });
                }
                
                e.preventDefault();
                e.stopImmediatePropagation();
                return false;
            }

        });
    }
}