/**
 * Create the Component object
 * @param {String|Node} elem    The element to make into a component
 * @param {Object}      options The component options
 */
'use strict';

import Dispatcher from '../dispatcher/dispatcher';
import Utils from '../lib/utils';

export default class Component {
    constructor(props) {
        if (!props.pure && !props.element) {
            throw 'Component: You did not provide an element to make into a component.';
        }

        this._id    = Utils.uuid();
        this._key   = null;

        if( !(typeof props[Symbol.iterator] === 'function') ) {
            props[Symbol.iterator] = () => {
                let i = this.length - 1;
                return {
                    next: () => {
                        return {
                            done: (i >= 0)
                                ? false
                                : true,
                            value: this[i--]
                        }
                    }
                };
            };
        }        

        this.state = Object.assign({
            element:    props.element,
            data:       props.data || null,
            template:   props.template || null,
            pure:       props.pure || false
        }, !!props ? props : {}, {
            set: (state, key, value) => {
                state[ key ] = value;
                this.dispatch('onStateChange', new CustomEvent('StateChange', {
                    detail: {
                        state: this.state
                    }
                }));
                return true;
            }
        });        

        // Event dispatcher
        this.Dispatcher = new Dispatcher();

        this.componentDidMount = this
            .componentDidMount
            .bind(this);
        this.componentWillUnmount = this
            .componentWillUnmount
            .bind(this);

        document.addEventListener('DOMContentLoaded', () => this.componentDidMount());
        window.onbeforeunload = () => this.componentWillUnmount();
    }

    // ---------------------------------------------------------- //
    // EVENT MANAGMENT
    // ---------------------------------------------------------- //
    dispatch( event, data ) {
        if( !event ) { throw "Event must not be null"; }

        let self = this;
        self.Dispatcher.publish(event, data);

        return self;
    }

    subscribe( event, callback ) {        
        if( !event ) { throw "Event must not be null"; }
        if( typeof callback !== 'function' ) { throw "Callback must be a valid function"; }

        let self = this;
        self.Dispatcher.subscribe( event, callback );

        return self;
    }

    // Add the `setState()` method
    setState(props) {
        
        // Shallow object merge
        for (var key in props) {
            if (Object.prototype.hasOwnProperty.call( props, key )) {
                this.state.data[key] = props[key];
            }
        }

        // Force a re-render
        this.render();
    }

    /**
     * Sanitize and encode all HTML in a user-submitted string
     * @param  {String} str  The user-submitted string
     * @return {String}      The sanitized string
     */
    sanitize(str) {
        let temp = document.createElement('div');
        temp.textContent = str;
        return temp.innerHTML;
    }

    template(props) {
        let template = `<div>Hello</div>`;
        return template ? template : null;
    }

    /**
     * Render a template into the DOM
     * @return {[type]} The element
     */
    render() {

        const {element, data} = this.state;

        // Make sure there's a template
        if (!this.template) { throw 'Component: No template was provided.'; }
        
        // If elem is an element, use it. If it's a selector, get it.
        let _element = typeof element === 'string' ? document.querySelector(elem) : elem;

        if (!elem) { return; }
        
        // Get the template, data will be passed as props to the template.
        let _template = typeof this.template === 'function' ? this.template(data) : this.template;

        // Array indexOf === -1 true if index value is not found.
        if (['string', 'number'].indexOf(typeof _template) === -1) { return; }
        
        // Do not render this template if identical
        if (_element.innerHTML === _template) { return; }

        // Render this template
        _element.innerHTML = _template; // else update with new template

        // Dispatch a render event -> https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent
        if (typeof window.CustomEvent === 'function') {
            let event = new window.CustomEvent('onComponentRender', {bubbles: true});
            _element.dispatchEvent(event);
        }

        // Return the _element for use elsewhere
        return _element;
    }

    get id() {
        return this._id;
    }

    set id( id ) {
        this._id= id;
    }

    get key() {
        return this._key;
    }

    set key( key ) {
        this._key= key;
    }

    // Component lifecycle methods
    componentDidMount() {}

    componentWillUnmount() {}

}
