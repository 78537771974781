import Utils from '../lib/utils'
import Component from './component'

export default class AnimationObserver extends Component {
    constructor( props ) {
        super( props )
        this.state = Object.assign(this.state, !!props ? props : {} )
        this.state.set(this.state, 'range', {
            'x': 0.4,
            'y': 0.6
        })
        this.state.set(this.state,'threshold',[0, 0.25, 0, 0.75, 1])
    }

    componentDidMount() {
        const self  = this
        const app    = self.state.app
        const el    = self.state.element
        const $     = self.state.$
        const threshold = self.state.threshold
        
        if( !el ) { throw "This component requires a valid DOM element." }

        if( el.length ) {
            const __options = {
                threshold: threshold // Trigger the observer at 25%, 75% and 100% when entering or leaving
            }
            const __observer = new IntersectionObserver( (entries) => {
                entries.map( entry => {   
                    window.requestAnimationFrame(() => {
                        this.toggle( entry )
                    })                 
                })
            }, __options )

            el.map( (i,v) => {
                __observer.observe( v )
            });

            app.subscribe('onAnimationToggle', (event) => {
                const entry = event.detail.entry;
            });
        }
    }

    toggle( entry ) {
        const self  = this
        const app   = self.state.app
        const range = self.state.range
        let r       = entry.intersectionRatio

        if( r >= range.x || r <= range.y ) {
            if( entry.isIntersecting ) {
                entry.target.classList.add('animation')
            }else{
                entry.target.classList.remove('animation')
            }

            app.dispatch('onAnimationToggle', new CustomEvent('AnimationToggle', {
                detail: {
                    entry: entry,
                    target: entry.target
                }
            }))
        }
    }
}
