import Utils from '../lib/utils'
import Component from './component'

export default class Modal extends Component {
    $_modalInstance = null;

    constructor( props ) {
        super( props )
        this.state          = Object.assign(this.state, !!props ? props : {} )
        this._key           = 'Modal_' + this._id
    }

    componentDidMount() {
        const self  = this
        const app   = self.state.app
        const el    = this.state.element
        const $     = this.state.$

        if( !el ) { throw "This component requires a valid DOM element." }

        // Collect all modal content instances
        this.#gatherModals()

        // Hook any CTAS for a booking modal
        $('body').on('click','a[modal], a.modal', (e) => {
            e.preventDefault()
            const obj     = $( e.target )
            const data    = obj.data('config')
            const id      = obj.prop('modalid') ?? obj.attr('modalid') ?? obj.data('modalid') ?? obj.prop('href')?.split('#').pop()
            const src     = this.#getModalSrc(id);
            self.show( src, {...data, 'id' : id} )
            return false
        })
    }

    #gatherModals() {
        const self  = this
        const $     = this.state.$
        const t     = this.state._templates = {}
        const templates = $('template.modalcontent')

        if(!!templates.length) {
            templates.map( (k, v) => {
                const m = $(v)
                t[m.prop('id')] = m.get(0).content
            })
        }
    }

    #getModalSrc(id) {
        const self      = this
        const t         = this.state._templates
        const template  = ((id in t) || t?.hasOwnProperty(id)) ? t[id] : null
        return (!!template) ? template.cloneNode(true) : null
    }

    show( src, data ) {
        const self  = this
        const app   = self.state.app
        const $     = self.state.$

        let html = []
        html.push( Utils.sprintf('<div id="ModalLeadForm-%s" class="modal modal--root">', data.id))
        html.push('    <div class="modal-scrim modal-scrim--root">')
        html.push('        <div class="modal-content">')
        html.push('                <div class="md-grid--container">')
        html.push('                        <button class="modal-close"><span class="md-icon">close</span></button>')
        html.push('                </div>')
        html.push('        </div>')
        html.push('    </div>')
        html.push('</div>')

        let modal   = $( html.join("\n") )
        let tmp     =  $( '<div />' ).append( src )

        modal.find('.md-grid--container').append( $(tmp.html()) )
        modal.on('click', '.modal-close', (e) => {
            e.stopImmediatePropagation()
            self.hide()
            return false
        })

        if( !!self._modalInstance ) {
            self.hide()
        }

        self._modalInstance = modal

        $('html').addClass('md-no-scroll')
        $('body').append( modal )
    }

    hide() {
        const self = this
        const $ = self.state.$
        $('html').removeClass('md-no-scroll')
        if( !!self._modalInstance ) {
            self._modalInstance.empty().remove()
        }
        self._modalInstance = null;
    }
}