import Utils from '../lib/utils'
import md5 from '@leonismoe/md5'
import Component from './component'

export default class LeadCTA extends Component {
    constructor( props ) {
        super( props )
        this.state          = Object.assign(this.state, !!props ? props : {} )
        this._key           = 'Forms_' + this._id
        this._categories    = []
        this._menubuttons   = []
        this._current       = null
        this._modalInstance = null
        this._formInstance  = null
        this._token         = null
    }

    componentDidMount() {
        const self  = this
        const app   = self.state.app
        const el    = this.state.element
        const $     = this.state.$

        if( !el ) { throw "This component requires a valid DOM element." }

        self._categories = $('menucategory', el)
        self._menubuttons = $('.menubutton.menubutton--root', el)

        // Hook any CTAS for a booking modal
        $('body').on('click','a.leadcta:not(.cta--booking),button.leadcta:not(.cta--booking)', (e) => {
            e.preventDefault()
            let obj = (!(e.target instanceof HTMLAnchorElement)) ? $(e.target?.closest('a.leadcta')) : $( e.target )
            let templateID = obj.prop('href')?.split('#').pop() || 'ModalFormContainer'

            if(!!templateID) {
                templateID = Utils.sprintf('#%s', templateID)
                let src = self.getCTAForm(templateID)
                if( !!src ) {
                    let formID = obj.data('formID') || src.querySelectorAll('input[name="SSFormID"]')[0]?.value || self._key || null
                    let config = {...obj.data('config')}
                    config.type = (!!obj.get(0)?.classList?.contains('leadcta--package')) ? 'package' : (obj.data('type') ?? null)

                    switch(config.type) {
                        case 'package' :
                            self.show(src, formID, {
                                id: obj.attr('packageid'),
                                title: obj.attr('packagetitle'),
                                type: 'package'
                            })
                            break
                        case 'contact' :
                        default:
                            self.show(src, formID, config)
                            break
                    }
                }
            }
            return false
        })
    }

    getCTAForm(templateID) {
        const self = this
        const $ = self.state.$
        const id = templateID
        const template = $(id)
        const formTemplate = !!template.length ? template.get(0).content : null

        if( !!formTemplate && !!formTemplate.querySelectorAll('form').length ) {
            self._formInstance = formTemplate
        }
        return !!self._formInstance ?  self._formInstance.cloneNode(true) : null
    }

    show( src, formID, data ) {
        const self  = this
        const app   = self.state.app
        const $     = self.state.$
        let id      = formID

        switch(data.type) {
            case 'package':
                if(!!data && 'id' in data && 'title' in data) {
                    $('input[name="PackageID"]', src).attr('value', data.id).prop('value', data.id)
                    $('input[name="PackageName"]', src).attr('value', data.title).prop('value', data.title)
                    let p = $('<p class="md-typography-body-1 package-label"></p>').text(data.title)
                    $('h2', src).after(p)
                }
                break
            case 'contact':
                if(data.Title) {
                    $('h2', src).text(Utils.sprintf('Contact %s', data.Title))
                }
                if(data.UUID) {
                    let dd = $('select[name="UUID"]', src)
                    $('select[name="UUID"] option', src).removeAttr('selected')
                    $(`select[name="UUID"] option[value="${data.UUID}"]`, src).attr('selected', 'selected')
                    dd.closest('.field.dropdown').addClass('field--focus')
                }
                break
        }

        let html = []
        html.push( Utils.sprintf('<div id="ModalLeadForm-%s" class="modal modal--root modal--leadform">', id))
        html.push('    <div class="modal-scrim modal-scrim--root">')
        html.push('        <div class="modal-content">')
        html.push('                <div class="md-grid--container">')
        html.push('                        <button class="modal-close"><span class="md-icon">close</span></button>')
        html.push('                </div>')
        html.push('        </div>')
        html.push('    </div>')
        html.push('</div>')

        let modal   = $( html.join("\n") )
        let tmp     =  $( '<div />' ).append( src )

        // Modify the form if a formID was supplied
        if( !!id ) {

            let objFrm = $('form', tmp)
            let field = $('input[name="formID"]', objFrm)

            // Append a formID field if we need it
            if( !field.length ) {
                let objformIDField = `<input type="hidden" name="formID" value="${formID}" class="hidden" id="${objFrm.prop('id')}_formID">`
                $('form',tmp).append( objformIDField )
            }
        }

        modal.find('.md-grid--container').append( $(tmp.html()) )
        modal.on('click', '.modal-close', (e) => {
            e.stopImmediatePropagation()
            self.hide()
            return false
        })

        // Remove existing modals
        self.hide()

        // Track the new modal
        self._modalInstance = modal

        app.subscribe('onAfterFormSubmit', (evt) => {
            if( evt.detail.key == self._key ) {
                let form = $(evt.detail.target)
            }
        })

        $('html').addClass('md-no-scroll')
        $('body').append( modal )
    }

    hide() {
        const self = this
        const $ = self.state.$
        $('html').removeClass('md-no-scroll')
        if( !! self._modalInstance ) {
            self._modalInstance.empty().remove()
        }

        self._modalInstance = null
    }
}
