import Utils from '../lib/utils'
import Siema from 'siema'
import Component from './component'

export default class Gallery extends Component {
    constructor( props ) {
        super( props )
        this.state = Object.assign(this.state, !!props ? props : {} )
        this._assets = {}
        this._galleries = {}
        this._configs = {}
        this._gallery = null
        this._modalInstance = null
        this.__interval = null
    }

    componentDidMount() {
        const self      = this
        const app       = this.state.app
        const el        = this.state.element
        const $         = this.state.$
        const blocker   = app.getComponentByKey('UIBlocker')

        if( !el ) { throw "This component requires a valid DOM element." }

        el.map((i,v) => {
            const id = v.dataset.galleryid
            if(!!id) {
                self._configs[id] = {...JSON.parse(v.dataset.config ?? '{}')}
            }
        })

        if( el.get(0)?.hasAttribute('interactive') ) {
            let assets = $('.gallery--asset', el)
            assets.map((i) => {
                let obj     = assets[i]
                let id      = !!obj.dataset.id ? `asset-${obj.dataset.id}` : `asset-${obj.id}`
                obj.classList.add('gallery--asset-interactive')
                self._assets[ id ] = {...$(obj).data()}

                // Collect all related images in individual galleries
                if(!self._galleries.hasOwnProperty(obj.dataset.galleryid)) {
                    self._galleries[ obj.dataset.galleryid ] = []
                }
                (self._galleries[ obj.dataset.galleryid ]).push({...$(obj).data()})
            })

            el.on('click', '.gallery--asset', (e) => {
                e.preventDefault()
                const element = e.target
                const guuid = element.dataset?.galleryid
                const gallery = !!guuid ? self._galleries[guuid] : null;
                const i = element?.dataset?.index

                if(!!gallery) {
                    self.showGallery(guuid, element, i)
                }

                return false
            })
        }
    }

    showGallery(id, trigger, index) {
        const self = this
        const app = self.state.app
        const $ = self.state.$
        const blocker   = app.getComponentByKey('UIBlocker')
        const gallery = !!id && self._galleries.hasOwnProperty(id) ? (self._galleries[id]).map( e => {
            const alt = e.alt || e.attribution || `Image-${e.id}`
            return {
                ...e,
                html: `<img src="${e.deferredSrc}" loading="lazy" id="asset-${e.id}" alt="${alt}" data-deferred-src="${e.hires}" class="non-deferred" data-defer-type="src" />`
            }
        }) : null

        if(!!gallery) {
            blocker.block(trigger)
            self.show(gallery, id, index)
            blocker.unblock(trigger)
        }

        return gallery
    }

    show( assets, id, index ) {
        const self = this
        const $ = self.state.$
        const c = self._configs[id] ?? {}
        let s = null

        let html = []
        html.push( `<div id="${id}" class="modal modal--root modal--galleryasset">`)
        html.push('    <div class="modal-scrim modal-scrim--root">')
        html.push(`        <div class="modal-content modal-content--gallery">`)
        html.push('            <div class="md-gallery"></div>')
        if(!!c.controls) {
            html.push('            <div class="md-gallery--controls">')
            html.push('                <button class="md-gallery--controls-prev" direction="prev"><span class="md-icon">arrow_left</span></button>')
            html.push('                <button class="md-gallery--controls-next" direction="next"><span class="md-icon">arrow_right</span></button>')
            html.push('            </div>')
        }
        html.push('        </div>')
        html.push('    </div>')
        html.push('</div>')

        const modal   = $( html.join("\n") )
        assets.map(e => {
            modal.find('.md-gallery').append( $(`<div class="md-gallery--item">${e.html}</div>`) );
        })

        // Carousel navigation
        modal.on('click', '.md-gallery--controls button', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            const d = e.target.getAttribute('direction')
            switch(d) {
                case 'next':
                    s.next()
                    break
                case 'prev':
                default:
                    s.prev()
                    break
            }
            s.resizeHandler();
        })

        if( !!self._modalInstance ) {
            self.hide()
        }

        self._modalInstance = modal

        $('html').addClass('md-no-scroll')
        $('body').prepend(modal)


        modal.on('click', '.modal-scrim', (e) => {
            e.stopImmediatePropagation()
            self.hide()
            return false
        })

        modal.on('click', '.md-gallery--item, .md-gallery--item img', (e) => {
            e.preventDefault()
            e.stopImmediatePropagation()
            return false
        })

        // Intialize a carousel
        if(!!$('.md-gallery').length) {
            s = new Siema({...c,...{
                selector: '.md-gallery'
            }})

            // Carousel index
            if(!!index && !!s) {
                s.goTo(Math.abs(index - 1))
            }else{
                s.goTo(0)
            }
            s.resizeHandler();
            if( !!c.loop && !!c.duration ) {
                this.__interval = setInterval(() => {
                    s.next()
                }, c.delay)
            }

            self._gallery = s
        }

    }

    hide() {
        const self = this
        const $ = self.state.$
        $('html').removeClass('md-no-scroll')
        if( !! self._modalInstance ) {
            self._modalInstance.empty().remove()
        }

        if(!!self._gallery) {
            self._gallery.destroy()
        }

        self._gallery = null
        clearInterval(this.__interval)
        this.__interval = null
    }

    loadMediaOffCanvas( asset, callback ) {
        if( !asset.hires ) {
            callback.apply(this,[])
            return false
        }
        let image = new Image()
        image.onload = () => {
            callback.apply(this,[image])
        }
        image.onerror = () => {
            console.log('Error loading image: ' + asset.hires)
            image = null
            callback.apply(this,[image])
        }
        image.src = asset?.hires
    }
}
