import Utils from '../lib/utils';
import Dispatcher from '../dispatcher/dispatcher';

let instance = null;

export default class Umbra {

    constructor(props) {
        if (!instance) { instance = this; }

        const self = instance;

        self.componentRegistry  = {};
        self.Dispatcher         = new Dispatcher();
        self.store              = Object.assign({}, props);
        if( 'Proxy' in window ) {
            self.state = new Proxy(( props.state || {}), {
                set: (state, key, value) => {
                    state[ key ] = value;
                    self.dispatch('onStateChange', new CustomEvent('StateChange', {
                        detail: {
                            state: self.state
                        }
                    }));
                    return true;
                }
            });
        }else{
            self.state = Object.assign({}, props.state, {
                set: (state, key, value) => {
                    state[ key ] = value;
                    self.dispatch('onStateChange', new CustomEvent('StateChange', {
                        detail: {
                            state: self.state
                        }
                    }));
                    return true;
                }
            });
        }

        self.ready = self
            .ready
            .bind(self);
        self.dispose = self
            .dispose
            .bind(self);

        document.addEventListener('DOMContentLoaded', () => self.ready());
        window.onbeforeunload = () => self.dispose();
        window.__Umbra__ = instance;

        return instance;
    }

    ready( callback ){
        if( !!callback ) {
            if( typeof callback !== 'function' ) { throw "Ready callback must be a valid function"; }
            callback.call(this,[]);
        }
    }

    dispose( callback ) {
        if( !!callback ) {
            if( typeof callback !== 'function' ) { throw "Dispose callback must be a valid function"; }
            callback.call(this,[]);
            console.log('Dispose', this);
        }
    }

    // ---------------------------------------------------------- //
    // STATE PROPS
    // ---------------------------------------------------------- //
    get mode() {
        return this.state.mode || 'dev';
    }

    // ---------------------------------------------------------- //
    // EVENT MANAGMENT
    // ---------------------------------------------------------- //
    dispatch( event, data ) {
        if( !event ) { throw "Event must not be null"; }

        let self = this;
        self.Dispatcher.publish(event, data);

        return self;
    }

    subscribe( event, callback ) {        
        if( !event ) { throw "Event must not be null"; }
        if( typeof callback !== 'function' ) { throw "Callback must be a valid function"; }

        let self = this;
        self.Dispatcher.subscribe( event, callback );

        return self;
    }

    // ---------------------------------------------------------- //
    // COMPONENT MANAGEMENT
    // ---------------------------------------------------------- //
    registerComponent( id, component, global ) {
        let self = this;
        let key = null;
        if( !Object.prototype.hasOwnProperty.call( self.componentRegistry, id ) ) {
            self.componentRegistry[ id ] = component;

            if( !!global ) {
                key = `__${component.key}__`;
                window[ key ] = component;
            }

            // Dispatch an event
            self.dispatch( 'onComponentRegistered', new CustomEvent('ComponentRegistered', {
                detail: {
                    id: id,
                    key: key,
                    component: self.componentRegistry[ id ]
                }
            }));

            return self;
        }

        throw `Component ${id} already exists. Use Umbra::updateComponent(...) instead`;
    }

    updateComponent( id, component ) {
        let self = this;
        if( !id ) { 'Component ID must not be null'; }
        self.componentRegistry[ id ] = component;

        // Dispatch an event
        self.dispatch( 'onComponentUpdated', new CustomEvent('ComponentUpdated', {
            detail: {
                id: id,
                component: self.componentRegistry[ id ]
            }
        }));
                
        return self;
    }

    removeComponent( id ) {
        const self = this;
        if( !id ) { 'Component ID must not be null'; }
        self.componentRegistry[ id ] = null;
        delete self.componentRegistry[ id ];

        // Dispatch an event
        self.dispatch( 'onComponentRemoved', new CustomEvent('ComponentRemoved', {
            detail: {
                id: id,
                component: null
            }
        }));

        return self;
    }

    getComponent( id ) {
        const self = this;
        return Object.prototype.hasOwnProperty.call( self.componentRegistry, id ) ?  self.componentRegistry[ id ] : null;
    }

    getComponentByKey( key ) {
        const self  = this;
        let match   = null;

        for( let k in self.componentRegistry ) {
            let c = null;
            if( Object.prototype.hasOwnProperty.call( self.componentRegistry, k ) ) {
                c = self.componentRegistry[ k ];
                if( c.key == key ) {
                    match = c;
                    break;
                }
            }
        }

        return match;
    }

    static uuid() {
        return Utils.uuid();
    }
}