import Component from './component';

export default class Fab extends Component {
    constructor( props ) {
        super( props );
        this.state = Object.assign(this.state, !!props ? props : {} );
    }

    // Like jquery's $() ready function
    componentDidMount() {
        const self = this;
        const el = this.state.element;

        if( !el ) { throw "This component requires a valid DOM element."; }

        el.addEventListener('click', (e) => {
            self.onClickHandler(e);
        }, true);
    }

    onClickHandler( e ) {
        e.preventDefault();
        const self      = this;
        const $         = self.state.$;
        const offset    = 0;

        window.requestAnimationFrame(() => {
            scroll({
                top: offset,
                behavior: 'smooth'
            });
        });
    }
}