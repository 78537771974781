import Utils from '../lib/utils'
import AssetHandler from './assethandler'
import Component from './component'

export default class Forms extends Component {
    constructor( props ) {
        super( props )
        this.state              = Object.assign(this.state, !!props ? props : {} )
        this.state.fileFields   = null
        this._key               = 'Forms'
        this._token             = null
    }

    componentDidMount() {
        const self      = this
        const app       = self.state.app
        const el        = this.state.element
        const $         = this.state.$
        const blocker   = app.getComponentByKey('UIBlocker')

        if( !el ) { throw "This component requires a valid DOM element." }

        self.initForm( el )

        // Block the form during submissions
        app.subscribe('onBeforeFormSubmit', (evt) => {
            if( evt.detail.key == self._key ) {
                let form = $(evt.detail.target)
                blocker.block( form )
            }
        })

        app.subscribe('onAfterFormSubmit', (evt) => {
            if( evt.detail.key == self._key ) {
                let form = $(evt.detail.target)
                blocker.unblock( form )
            }
        })

        self.subscribe('onFormResponse', (data) => {
            self.update( data.detail )
        })
    }

    initForm( form ) {
        const self      = this
        const app       = self.state.app
        const $         = self.state.$
        const el        = $( form )
        const endpoint  = el.attr('action')

        // Files
        const fields = el.find('input[type="file"]')
        if(!!fields.length) {
            const files = self.state.fileFields = new Map();
            fields.toArray().map((o) => {
                files.set(o.name, new AssetHandler({app:app, element: $(o), $:$}))
            })
        }

        el.on('submit', (e) => {
            e.preventDefault()
            e.stopImmediatePropagation()
            let isValid = true

            // Basic validation
            let emailConfirm = $('input[type="email"][mustmatch]', el)
            if( emailConfirm.length ) {
                let fieldname = emailConfirm.attr('mustmatch')
                let matchingField = $( Utils.sprintf('input[type="email"][name="%s"]',fieldname), el)
                if( !!matchingField.length ) {
                    isValid = !!(emailConfirm.val() == matchingField.val())
                    if(!isValid) {
                        isValid = false
                        alert('Please ensure your emails match.')
                        matchingField.focus().click()
                        return
                    }
                }
            }

            if( !!isValid ) {

                // Handle recaptcha
                if( ('grecaptcha' in window) && app.state.site_key ) {
                    grecaptcha.ready( () => {
                        grecaptcha.execute(app.state.site_key,{action: `submit`}).then( (token) => {
                            this._token = token
                            // Handle recaptcha token injection
                            $(form).find('input[name="g-recaptcha-response"]').prop('value', self._token)

                            let data = self.serialize(form, true)
                            self.notifyFormSubmit( 'BeforeFormSubmit', form, data )

                            fetch( endpoint, {
                                method: 'POST',
                                body: data,
                                headers: {
                                    'Content-type': 'application/json charset=UTF-8',
                                    'X-Requested-With': 'XMLHttpRequest'
                                }
                            }).then( (response) => {
                                if (response.ok) {
                                    return response.json()
                                }
                                return Promise.reject(response)
                            }).then((data) => {
                                let details = {...data}
                                details.target = form

                                self.notifyFormSubmit( 'AfterFormSubmit', form, details )
                                self.dispatch('onFormResponse', new CustomEvent('FormResponse', {
                                    detail: details
                                }))
                            }).catch((error) => {
                                self.notifyFormSubmit( 'AfterFormSubmit', form, error )
                            })
                        })
                    })
                }

            }

            return false
        })
    }

    notifyFormSubmit( event, form, data ) {
        const self = this
        const app = self.state.app

        // Dispatch an event for other components to hook
        app.dispatch(Utils.sprintf('on%s',event), new CustomEvent(event, {
            detail: {
                key: self._key,
                target: form,
                data: data
            }
        }))
    }

    /**
     * Form submission handler.
     *
     * @todo There might be a possible double-submission bug with the modal form.
     *
     * @param {CustomEvent} event
     */
    update( event ) {
        const detail    = event
        const self      = this
        const $         = self.state.$
        const form      = $( detail.target ) // Original form
        const message   = $( Utils.sprintf( '<div class="md-grid--item md-form-message md-form-message--root"><div class="md-form-message-content">%s</div></div>', detail.data.content.Content ) )
        const nothanks  = !!form.data('nothanks')
        const respform  = detail.data.form

        // Replace the form's content
        if( !!nothanks ) {
            // @todo provide a nicer UX with some visual feedback
            if( !!respform ) {
                form.html( respform )
            }else{
                form.get(0).reset()
            }
        }else{
            form.html( message )
        }

        // Bind an event handler for the FORM transition OUT
        // Note: Disabled this feature for now until a proper form
        // can be generated to match what's being replaced
        // message.one('transitionend', (e) => {
        //     setTimeout(() => {
        //         form.one('transitionend', (e) => {
        //             let f = $(detail.data.form)
        //             window.requestAnimationFrame(() => {
        //                 form.html( f.html() )
        //                 form.addClass('md-form-enhanced--in')
        //                 form.removeClass('md-form-enhanced--out')
        //             })
        //         })

        //         window.requestAnimationFrame( () => {
        //             form.addClass('md-form-enhanced--out')
        //         })
        //     },16000)
        // })

        window.requestAnimationFrame( () => {
            message.addClass('md-form-message--in')
        })
    }

    // ---------------------------------------------------------- //
    // HELPER METHODS
    // ---------------------------------------------------------- //
    serialize( form, asJSON ) {
        const self  = this;
        const f     = new FormData(form)
        const files = self.state.fileFields;
        const ids   = [];
        let data    = null;

        // Handle files that come from extra form fields
        if(!!files && files instanceof Map && !!files?.size) {
            files.forEach( (value, key, map) => {
                const id = !!(value instanceof AssetHandler) ? value.toString() : null
                ids.push(id)
                f.set(key, id)
            });
            data = Object.fromEntries(f)
            if(ids.length) {
                data.Files = ids
            }
        }else{
            data = Object.fromEntries(f)
        }

        return !!asJSON ? JSON.stringify(data) : data
    }
}