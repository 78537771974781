import Component from './component';
import Cookies from 'js-cookie';

export default class CookieConsent extends Component {
    constructor( props ) {
        super( props );
        this.state = Object.assign(this.state, !!props ? props : {} );
    }

    componentDidMount() {
        const self  = this;
        const el    = this.state.element;
        const $     = this.state.$;

        if( !el ) { throw "This component requires a valid DOM element."; }
        
        // Event handlers for cookie consent buttons
        let body = $('body');
        body.on('click','.js-cookie-consent-button', (e) => {
            e.preventDefault();
            self.accept();
            return false;
        });

        body.on('click','a[inline].js-cookie-consent-button', (e) => {
            e.preventDefault();
            console.log('inline consent', $(e.currentTarget));
            self.accept($(e.currentTarget));
            return false;
        });

        self.subscribe('onCookieConsentAccepted', (event) => {
            if( 'url' in event && !!event.url ) {
                window.location.href = event.url;
            }
        });
    }

    accept(trigger) {
        const self  = this;
        const el    = this.state.element;
        let url     = (!!trigger && trigger.length) ? trigger.attr('href') : null;
        Cookies.set('CookieConsent', 'true', {expires:365});
        el.attr('hidden',true);
        self.dispatch('onCookieConsentAccepted', {obj:self, el:el, url:url});
        return self;
    }
}
