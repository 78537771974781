import Cookies from 'js-cookie';
import Component from './component';
import Mutator from "../lib/mutator"

export default class Popup extends Component {
    constructor( props ) {
        super( props )
        this.state              = Object.assign(this.state, !!props ? props : {} )
        this.state._templates   = {}
        this._key               = 'Forms_' + this._id
        this._current           = null
        this._dialogInstance    = null
        this._formInstance      = null
        this._token             = null
        this._observer          = null
        this._types             = ['auto', 'default']
        this.__to               = null
        this.__ignore           = null
    }

    componentDidMount() {
        const self  = this
        const app   = self.state.app
        const el    = this.state.element
        const $     = this.state.$

        if( !el ) { throw "This component requires a valid DOM element." }

        // Hook any CTAS for a popup modal
        $('body').on('click','.popup:not(.leadcta),[popup]:not(.leadcta)', (e) => {
            const obj = e.target
            const templateID = !!obj.href ? obj.href.split('#').pop() : null
            const config = self.getPopup(templateID)

            if(!!templateID && data) {
                e.preventDefault()
                self.show(config)
            }
        })

        self.gatherModals();

        self.autorun();
    }

    gatherModals() {
        const self  = this
        const $     = this.state.$
        const t     = this.state._templates = {}
        const templates = $('template.popuptemplates')

        self._types.map( type => t[type] = {})

        if(!!templates.length) {
            templates.map( (k, v) => {
                const instance = v.content.querySelector('popup')
                const data = !!instance.getAttribute('config') ? JSON.parse(instance.getAttribute('config')) : null;
                const type = !!data.auto ? 'auto' : 'default'
                const cookieid = ('id' in data) ? data.id : null

                // ONLY add popups that don't have cookies set
                if(!(cookieid && !!Cookies.get(cookieid)) || !!data.ignorecookie) {
                    t[type][data.key] = {
                        ...data,
                        instance: instance
                    }
                }
            })
        }
    }

    getPopup(id) {
        const self      = this
        const t         = this.state._templates
        const instance  = (!!t && (id in t)) ? t[id] : null
        return (!!instance && 'instance' in instance) ? instance.instance.cloneNode(true) : null
    }

    autorun() {
        const self = this;
        const t = self.state._templates
        const keys = Object.keys(t.auto)

        // Only extract ONE auto popup
        if(!!keys.length) {
            const key       = keys.shift()
            const config    = t.auto[key]

            self.__to = setTimeout(() => {
                self.show(config);
                clearTimeout(self.__to)
            }, config.delay);
        }
    }

    show(data) {
        const self          = this
        const app           = self.state.app
        const $             = self.state.$
        const src           = data.instance
        const id            = data.id
        const tmp           = $( '<div />' ).append(src)
        const ariaLabel     = $(`#${id}_title`, src).text()
        const attr          = `popup ${data.media ? 'media' : ''} ${data.imageonly ? 'imageonly' : ''} aspectratio="${data.aspectratio ?? '4:3'}" orientation="${data.orientation ?? 'landscape'}"`

        const dialog = $(
            `<dialog ${attr} id="${id}" aria-labelledby="${id}_title" aria-describedby="${id}_description" class="uielementaldialog uielementaldialog--root">
                <dialogheading class="uielementaldialog-heading">${ariaLabel}</dialogheading>
                <dialogcontent class="uielementaldialog-content"></dialogcontent>
                <button class="uielementaldialog-close"><span class="md-icon">close</span></button>
            </dialog>`
        )

        dialog.find('dialogcontent').append( $(tmp.html()) )
        dialog.on('click', '.uielementaldialog-close', (e) => {
            e.stopImmediatePropagation()
            self.hide()
        })

        dialog.on('close', (e) => {
            self.hide()
        })

        // Recaptcha
        if( 'grecaptcha' in window && !!grecaptcha ) {
            const sitekey = app.state.site_key || $('script[data-sitekey]').data('sitekey');
            grecaptcha
                .execute(sitekey, {action: "validate_captcha"})
                .then((e) => {
                    $('.recaptchaResponse', dialog).map( (k, v) => {
                        v.value = e
                        v.setAttribute('value', e)
                    })
                })
        }

        // Remove existing dialogs
        self.hide()

        // Track the new dialog
        self._dialogInstance = dialog.get(0)

        $('html').addClass('md-no-scroll')
        $('body').append( dialog )

        self._observer = new Mutator({
            selector: 'dialog[popup]',
            callback: (e) => {
                window.requestAnimationFrame(() => {
                    e.forEach((v) => {
                        v.showModal()
                    })
                })
            }
        })
    }

    hide() {
        const self = this
        const $ = self.state.$
        const cookieid = !!self._dialogInstance ? self._dialogInstance.id : null
        $('html').removeClass('md-no-scroll')

        if(!!self._dialogInstance && self._dialogInstance instanceof HTMLElement) {
            self._dialogInstance.close()
            self._dialogInstance.remove()

            if(!!cookieid) {
                Cookies.set(cookieid, 'true', {expires:365})
            }
        }

        self._dialogInstance = null
    }
}