export default class Mutator {
    constructor( props ) {
        this.__objMutatorConfig    = ('options' in props) ? {
            attributes: true, childList: true, subtree: true,
            ...props.options
        } : { attributes: true, childList: true, subtree: true }
        this.__arMutatorAttributes = Object.keys( this.__objMutatorConfig ).map( item => item.toLowerCase() )
        this.__props = {
            selector: null,
            callback: null,
            ...props
        }

        this.observe()
    }

    observe() {
        if( !('selector' in this.__props) || !('callback' in this.__props) ) {
            return false
        }

        // Immediately trigger the callback to bind elements that already exist in the DOM
        if( document.querySelectorAll( this.__props.selector ).length ) {
            this.__props.callback.apply(this, [document.querySelectorAll( this.__props.selector )])
        }

        // Return a MutationObserver for recurring instances
        return (new MutationObserver( (mutations) => {
            mutations.map( (mutation) => {

                let added = [...mutation.addedNodes]
                if( !!this.__arMutatorAttributes.includes(mutation.type.toLowerCase()) && !!added.length ) {
                    added.map((v) => {
                        let dom = !!( v instanceof HTMLElement ) ? v : document
                        if( !!dom.querySelectorAll( this.__props.selector ).length ) {
                            let types = [1,2,3]
                            if( types.includes( v.nodeType ) ) {
                                this.__props.callback.apply(this, [dom.querySelectorAll( this.__props.selector )])
                            }
                        }
                    })
                }

            } )
        })).observe( document.querySelector('html'), this.__objMutatorConfig )
    }
}
