import Utils from '../lib/utils';
import Component from './component';
import Forms from './forms';
import MDForms from './mdforms';

export default class FormObserver extends Component {
    constructor( props ) {
        super( props );
        this.state = Object.assign(this.state, !!props ? props : {} );
    }

    componentDidMount() {
        const self  = this;
        const el    = this.state.element;
        const $     = this.state.$;

        if( !el ) { throw "This component requires a valid DOM element."; }
     
        // DOM Mutation Observer for: AJAX Enhanced forms
        const __obFormObserverConfig = { attributes: true, childList: true, subtree: true };
        const __arFormObjserverTypes = ['childlist','subtree'];
        const __objFormObserver = new MutationObserver( (mutations) => {
            
            mutations.map( (mutation) => {
                let added       = Array.prototype.slice.call( mutation.addedNodes );
                if( !!__arFormObjserverTypes.includes(mutation.type.toLowerCase()) ) {
                    if( !!added.length ) {
                        added.map((v) => {
                            
                            if( 1 === v.nodeType ) {
                                let type = v.nodeName.toLowerCase();
                                let obj = $( v );
                                let objOBEnhancedForm = null;
                                
                                if( __arFormObjserverTypes.includes(type) && obj.hasClass('md-form-enhanced') ) {
                                    // Handle the immediate node that triggered the MutationObserver.
                                    // This condition will only be met by matching nodes.
                                    self.initForm( obj );
                                }else{
                                    let frm = $('form.md-form-enhanced', $(v));
                                    if( !!frm && !!frm.length ) {
                                        self.initForm( frm );
                                    }
                                }

                                if( !!objOBEnhancedForm ) {
                                    objOBEnhancedForm.componentDidMount();
                                }
                            }

                        });
                    }
                }

            } );
        });

        __objFormObserver.observe( el.get(0), __obFormObserverConfig );        
    }

    initForm( form ) {
        const self = this;
        const U = self.state.app;
        const $ = self.state.$;

        let objStandardForm = null;
        let objOBEnhancedForm = null;
                                
        // Initialize standard forms
        objStandardForm = new MDForms({app:U, element: form, $:$});
        U.registerComponent( objStandardForm.id, objStandardForm.key );

        // Intialize the enhanced forms
        objOBEnhancedForm = new Forms({app:U, element:form.get(0), $:$});
        objOBEnhancedForm.key = Utils.sprintf("%s_%s",objOBEnhancedForm.key, Utils.uuid());
        U.registerComponent( objOBEnhancedForm.id, objOBEnhancedForm, objOBEnhancedForm.key );

        if( !!objOBEnhancedForm ) {
            objOBEnhancedForm.componentDidMount();
        }

        if( !!objStandardForm ) {
            objStandardForm.componentDidMount();
        }
    }
}