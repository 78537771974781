import Component from './component'
import Siema from 'siema'
import Utils from '../lib/utils'

export default class Carousel extends Component {
    constructor( props ) {
        super( props )
        this.state = Object.assign(this.state, !!props ? props : {} )
        this.collection = []
        this.pages = null;
    }

    componentDidMount() {
        const self  = this
        const el    = this.state.element
        const $     = this.state.$

        if( !el ) { throw "This component requires a valid DOM element." }

        el.map((k,v) => {
            self.initCarouselInstance(v)
        })
    }

    initCarouselInstance( element ) {
        if( !element ) { return false }

        const self          = this
        const $             = self.state.$
        const slides        = []
        const el            = $( element )
        let config          = el.data('config') || {}

        if( config.hasOwnProperty('custom') && !!(config.custom || false) ) {
            self.state.set(self.state, 'config', {...config})
        }

        // If there are no instances or empty elemental blocks, do not continue
        if(!$(config.selector).length) {
            return false
        }

        // Additional classes
        $('.carousel--slide, slide', el).map((k,v) => {
            let slide = $(v)
            slides.push(slide)
        })

        // Pages
        self.pages = $( el ).find('.carousel--page');

        let objCarousel = new Siema({...self.state.config,
            onInit: () => {
                if( !!slides.length ) {
                    window.requestAnimationFrame(() => {
                        slides.map((v,k) => {
                            let slide = v
                            let parent = slide.parent()
                            parent.addClass('carousel--slide-wrapper')
                            parent.parent().addClass('carousel--slide-container')
                        })
                        self.onChange( el, 0, slides )
                    })
                }
            },
            onChange: () => {
                if( !!slides.length ) {
                    window.requestAnimationFrame(() => {
                        self.onChange( el, Math.ceil(objCarousel.currentSlide), slides )
                    })
                }
            }
        })

        // Pagination
        if( !!self.pages.length ) {
            $( el ).on('click', '.carousel--pagination .carousel--page', (e) => {
                e.preventDefault();
                const data = $(e.target).data()

                if('index' in data) {
                    let i = data.index
                    objCarousel.goTo( Math.abs(i - 1) )
                }

            })
        }

        $( el ).on('click','.carousel--prev, .carousel--next', (e) => {
            e.preventDefault()
            const obj = $(e.target)
            let action = obj.data('action')
            window.requestAnimationFrame(() => {
                switch( action ) {
                    case 'prev':
                        objCarousel.prev()
                        break
                    case 'next':
                    default:
                        objCarousel.next()
                        break
                }
            })
        })

        // Autoplay slides
        if( !!self.state.config.loop && !!self.state.config.duration ) {
            setInterval(() => objCarousel.next(), self.state.config.delay)
        }

        self.collection.push( objCarousel )
    }

    onChange(element, current, slides) {
        const self  = this
        const $     = self.state.$
        const el    = element
        const slideCounter = $('.carousel--slidecounter',el)
        const currentSlide = slides[current]

        // Track and mark the current slide
        slides.map((v,k) => {
            let slide = v
            slide.removeClass('carousel--slide-current')
        })
        currentSlide.addClass('carousel--slide-current')

        // Burn-lines
        if( !!slides.length && !!slideCounter.length ) {
            // Slide markers
            if( !!slideCounter.length ) {
                let i           = current + 1
                let slideMarkers = $('.carousel--slidemarker',slideCounter)
                slideMarkers.map((k,v) => {
                    let marker = $(v)
                    let index = marker.data('slideindex')
                    if( index !== i ) {
                        marker.removeClass('current')
                    }else{
                        marker.addClass('current')
                    }
                })
                slideCounter.data('slideindex', i)
                slideCounter.attr('data-slideindex', i)
            }
        }

        // Slide Counter
        let slidestats = $('slidestats', el)
        if( !!slides.length && !!slidestats.length ) {
            window.requestAnimationFrame(() => {
                let statCurrent = $('stat[current]', slidestats)
                let statTotal = $('stat[total]', slidestats)

                statTotal.text(slides.length)
                statCurrent.text((current + 1))
            })
        }

        if(!!self.pages?.length) {
            window.requestAnimationFrame(() => {
                self.pages.map((n,p) => {
                    $(p).removeClass('carousel--page-current')
                })
                self.pages.eq(current).addClass('carousel--page-current')
            });
        }

    }
}