import Component from './component';
import Utils from '../lib/utils';

export default class UIBlocker extends Component {
    constructor( props ) {
        super( props );
        this.state = Object.assign(this.state, !!props ? props : {} );
        this._instances = {};
        this._key = 'UIBlocker';
    }

    componentDidMount() {
        const self  = this;
        const el    = this.state.element;
        const app   = this.state.app;
        const $     = this.state.$;

        let blocker = [];
        blocker.push('<div class="uiblocker uiblocker--root">');
        blocker.push('  <div class="uispinner uispinner--root">');
        blocker.push('      <div class="uispinner-dot"></div>');
        blocker.push('      <div class="uispinner-dot"></div>');
        blocker.push('      <div class="uispinner-dot"></div>');
        blocker.push('      <div class="uispinner-dot"></div>');
        blocker.push('  </div>');
        blocker.push('</div>');

        // Internally generated
        self.state.element = $(blocker.join("\n")).get(0);
    }

    block( parent, blockroot ) {
        const self      = this;
        const $         = self.state.$;
        const el        = self.state.element;

        const id        = Utils.sprintf("uiblocker-%s",Utils.uuid());
        el.id           = id;
        let blocker     = $( el ).clone(true);
        let container   = $( parent );
        blocker.addClass( !!blockroot ? 'uiblocker-rootblocker' : 'uiblocker-inlineblocker');
        container.prepend(blocker);

        self._instances[ id ] = blocker;

        return self._instances[ id ];
    }

    unblock( parent ) {
        const self      = this;
        const $         = self.state.$;
        const blocker   = $('.uiblocker', parent);
        let id          = null;
        let result      = false;

        if( !!blocker.length ) {
            id = blocker.get(0).id;
            blocker.empty().remove();
            delete self._instances[ id ];
            result = true;
        }

        return result;
    }
}